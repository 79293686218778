import React, { useEffect, useState } from "react";
import {
    KeyboardDatePicker,
    MuiPickersUtilsProvider,
} from "@material-ui/pickers";
import MomentUtils from "@date-io/moment";
import { makeStyles } from "@material-ui/core/styles";
import moment from "moment";
// import MROTextField from "./TextField";

import EventIcon from "@material-ui/icons/Event";
import { InputAdornment } from "@material-ui/core";
import MROTextField from "components/form_components/TextField";

const useStyles = makeStyles((theme) => ({
    root: {
        "& input::placeholder": {
            textTransform: 'unset'
        },
        margin: theme.spacing(1, 0),
        "& .MuiOutlinedInput-root": {
            "& fieldset": {
                borderColor: theme.palette.primary.main,
                borderWidth: "1px !important",
            },
            "&:hover fieldset": {
                borderColor: theme.palette.primary.light,
                // borderWidth: '1px !important'
            },
            "&.Mui-error:hover  fieldset": {
                borderColor: "#f44336 !important",
            },
            "& .MuiOutlinedInput-input": {
                padding: "15px",
            },
        },
        "& label": {
            color: theme.palette.primary.main,
        },
    },
}));

export const checkFromat = (date) =>
    /^\d{4}\-(0?[1-9]|1[012])\-(0?[1-9]|[12][0-9]|3[01])$/.test(date);

function DateField(props) {
    const classes = useStyles();
    const [selectedDate, setSelectedDate] = useState(null);
    const {
        label,
        name,
        defaultValue,
        variant,
        onChange,
        error,
        helperText,
        disabled,
        calenderAction,
        type,
        maxDate,
        value,
        setRepeatDate
    } = props;


    const outputFormat = "DD/MM/YYYY";
    useEffect(() => {
        setSelectedDate(defaultValue ? moment(
            defaultValue,
            checkFromat(defaultValue) ? "YYYY-MM-DD" : calenderAction ? "YYYY-MM-DD hh:mm:ss" : "DD/MM/YYYY"
        ).format("lll")
            : ""
        );
    }, [defaultValue]);

    if (disabled) {
        return (
            <MROTextField
                disabled
                label={label}
                name={name}
                color={"primary"}
                InputLabelProps={{ shrink: true }}
                value={selectedDate}
                InputProps={{
                    endAdornment: (
                        <InputAdornment position="end">
                            <EventIcon />
                        </InputAdornment>
                    ),
                }}
            />
        );
    }

    return (
        <MuiPickersUtilsProvider utils={MomentUtils}>
            <KeyboardDatePicker
                clearable
                className={classes.root}
                fullWidth
                autoOk
                focused
                inputVariant="outlined"
                label={label}
                variant={variant}
                InputProps={{ readOnly: true }}
                color={"primary"}
                placeholder={"DD/MM/YYYY"}
                format={outputFormat}
                value={selectedDate && selectedDate}
                error={error && error}
                helperText={helperText}
                maxDate={maxDate && maxDate}
                InputAdornmentProps={{ position: "end" }}
                onChange={(date) => {
                    setSelectedDate(date);
                    if (type === "series" && selectedDate === "Invalid date") {
                        setRepeatDate(date)
                    }
                    onChange && onChange(moment(date).format("DD/MM/YYYY"));
                }}
            />
            <input
                type={"hidden"}
                defaultValue={defaultValue}
                name={name || "date"}
                value={selectedDate ? moment(selectedDate).format(type === "shifts" ? "YYYY-MM-DD HH:mm" : "YYYY-MM-DD") : ""}
            />
        </MuiPickersUtilsProvider>
    );
}

export default DateField;
